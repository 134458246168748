import React from "react"

const ServiceSchema = () => {
  const jsonSchema = {
    "@context": "http://www.schema.org",
    "@type": "EntertainmentBusiness",
    name: "Atle Holtan",
    url: "https://atleholtan.no",
    telephone: "+4792024756",
    // logo: "www.yourbusiness.com/logo.jpg",
    priceRange: "$$$$",
    image: "https://atleholtan.no/business-image.jpg",
    description:
      "Dependable and creative Steadicam Operator in Norway and around the globe.",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Oslo",
      postalCode: "0150",
      addressCountry: "NO",
    },
  }
  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonSchema) }}
    />
  )
}

export default ServiceSchema
