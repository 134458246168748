import React from "react"

import Button from "../../components/Button/Button"

import "./FooterLayout.scss"

const FooterLayout = () => (
  <React.Fragment>
    <footer className="footer">
      <div className="footer__item">
        <a
          href="https://www.imdb.com/name/nm2161678/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="imdb" text="Check IMDb" />
        </a>
      </div>
      <div className="footer__item">
        <a
          href="https://vimeo.com/user62627427"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="vimeo" text="See Reel" />
        </a>
      </div>
      <div className="footer__item">
        <a
          href="mailto:atle+homepage@atleholtan.no?subject=Steadicam work"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="email" text="Contact" />
        </a>
      </div>
    </footer>
  </React.Fragment>
)

export default FooterLayout
