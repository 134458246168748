import React from "react"
import { string, oneOf } from "prop-types"
import { FaImdb, FaVimeoSquare } from "react-icons/fa"
import { MdEmail } from "react-icons/md"

import "./Button.scss"

const Button = ({ icon, text }) => {
  let iconComponent
  switch (icon) {
    case "email":
      iconComponent = <MdEmail className="button__icon" />
      break
    case "imdb":
      iconComponent = <FaImdb className="button__icon" />
      break
    case "vimeo":
      iconComponent = <FaVimeoSquare className="button__icon" />
      break
    default:
      iconComponent = null
  }
  return (
    <div className="button__block">
      <h2 className="button__text">{text}</h2>
      {iconComponent}
    </div>
  )
}
Button.defaultProps = {
  icon: null,
}
Button.propTypes = {
  icon: oneOf(["email", "imdb", "vimeo"]),
  text: string.isRequired,
}
export default Button
