import React from "react"

import "./DescriptionLayout.scss"

const DescriptionLayout = () => (
  <div className="description-layout">
    <h3 className="description-layout__title">
      When you want the scene to come alive
    </h3>
    <p className="description-layout__description">
      Atle Holtan is a dependable and creative steadicam operator with over 15
      years of experience. <br />
      He has trained with the best -{" "}
      <a
        href="https://en.wikipedia.org/wiki/Garrett_Brown"
        target="_blank"
        rel="noreferrer noopener"
        style={{ color: "#fff", textDecoration: "underline" }}
      >
        Garrett Brown
      </a>{" "}
      and{" "}
      <a
        href="https://www.jerryholway.com/bio"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#fff", textDecoration: "underline" }}
      >
        Jerry Holway
      </a>
      . Atle has adapted the ability to visualize and solve single scenes as
      well as long master-shots. Translating your vision to camera.
    </p>
  </div>
)

export default DescriptionLayout
