import React from "react"
import Helmet from "react-helmet"
import Image from "../../components/Image/Image"
import ServiceSchema from "../../components/ServiceSchema/ServiceSchema"
import DescriptionLayout from "../DescriptionLayout/DescriptionLayout"
import FooterLayout from "../FooterLayout/FooterLayout"

import "./HomePageLayout.scss"

const HomePageLayout = () => (
  <div className="homepage-layout">
    <Helmet>
      <html lang="en" />
      <meta
        name="google-site-verification"
        content="xZ5RayZx55jOfw6j8kVteQhmZqBoD1IMT8Q6pkxZPsk"
      />
      <title>Atle Holtan | Steadicam Operator in Norway</title>
      <meta
        name="description"
        content="Dependable and Creative Steadicam Operator in Norway and around the globe."
      />
    </Helmet>
    <div className="homepage-layout__hero">
      <Image>
        <div className="homepage-layout__hero-content">
          <h1 className="homepage-layout__hero-heading">Atle Holtan</h1>
          <h2 className="homepage-layout__hero-subheading">
            Steadicam Operator
          </h2>
          <h2 className="homepage-layout__hero-subheading">Oslo, Norway</h2>
        </div>
      </Image>
    </div>
    <div className="homepage-layout__content">
      <DescriptionLayout />
      <ServiceSchema />
      <FooterLayout />
    </div>
  </div>
)
export default HomePageLayout
